<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검토자 -->
          <c-field 
            type="user" 
            label="LBLREVIEWER" 
            name="examineUserId" 
            v-model="searchParam.examineUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 승인자 -->
          <c-field 
            type="user" 
            label="LBLAPPROVER" 
            name="approvalUserId" 
            v-model="searchParam.approvalUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 안전환경보건 규정 목록 -->
    <c-table
      ref="guideBook"
      title="LBL0010346"
      tableId="guideBook"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :editable="editable"
      rowKey="guideBookId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="bookPopup" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template>
          <q-btn 
            round unelevated 
            size="6px"
            color="amber" 
            icon="folder"
            @click="rowClick2(props.row, props.pageIndex)" />
        </template>
        <template>
          <q-btn 
            round unelevated 
            size="6px"
            color="blue" 
            icon="download"
            @click="rowClick2(props.row, props.pageIndex)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-oper-guide-book',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장명
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'guidebookNo',
            field: 'guidebookNo',
            // 분류번호
            label: 'LBL0010347',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'guidebookName',
            field: 'guidebookName',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:200px',
            type: "link",
            sortable: true,
          },
          {
            name: 'examineUserName',
            field: 'examineUserName',
            // 검토자
            label: 'LBLREVIEWER',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            // 승인자
            label: 'LBLAPPROVER',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            // 규정내용
            label: 'LBL0010348',
            type: 'html',
            align: 'center',
            style: 'width:300px',
          },
          {
            name: 'attach',
            field: 'attach',
            // 관련 파일첨부
            label: 'LBL0010349',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'SAF_OPER_GUIDE_BOOK',
            keyText: 'opmGuidebookId',
            sortable: true,
          },
          {
            name: 'useFlagName',
            field: 'useFlagName',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width:80px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        examineUserId: '',
        approvalUserId: '',
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sop.opm.guidebook.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./safetyOperGuideBookDetail.vue"}`);
      this.popupOptions.title = 'LBL0010350';   // 안전환경보건 규정 상세
      this.popupOptions.param = {
        opmGuidebookId: result ? result.opmGuidebookId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
